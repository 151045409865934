import React from 'react'
import SEO from '../components/Seo'
import { graphql } from 'gatsby'
import Section from '../components/cms/Section'
import PropTypes from 'prop-types'
import { RepairBlogCategorySectionRenderer } from '../section-renderer/repair-blog-category'

const RepairBlogCategoryPage = ({ data }) => {
  return (
    <RepairBlogCategorySectionRenderer data={data}/>
  )
}


RepairBlogCategoryPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      brand: PropTypes.string,
      safeHeroImage: PropTypes.object,
      models: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      url: PropTypes.string,
      table_of_contents: PropTypes.bool,
      createdAt: PropTypes.string,
      publishedAt: PropTypes.string,
      updatedAt: PropTypes.string,
      seo: SEO.propTypes.seo,
      sections: PropTypes.arrayOf(Section.propTypes.section),
      tag: PropTypes.arrayOf(
        PropTypes.shape({
          location: PropTypes.string,
          service: PropTypes.string,
          category: PropTypes.string,
        })
      ),
      services: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
  }),
}

export const query = graphql`
  query RepairBlogCategoryQuery($pageId: Int!) {
    page: strapiRepairBlogCategory(strapi_id: { eq: $pageId }) {
      safeHeroImage {
        gatsbyImageDataMock
      }
      brand
      models: allModels {
        title
        url
      }
      services {
        label: title
        url
      }
      url: urlPath
      table_of_contents
      createdAt
      publishedAt
      updatedAt
      tag {
        location
        service
        category
      }
      seo {
        ...SEO
      }
      sections {
        ... on STRAPI__COMPONENT_SECTION_YOUTUBE_VIDEOS {
          ...YoutubeSection
        }
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH {
          ...ParagraphSection
        }
        ... on STRAPI__COMPONENT_SECTION_IMAGE_SECTION {
          ...ImageSection
        }
        ... on STRAPI__COMPONENT_SECTION_NUMBERED_LIST {
          ...NumberedListSection
        }
        ... on STRAPI__COMPONENT_SECTION_SECTION_LAYOUT {
          ...SectionLayout
        }
        ... on STRAPI__COMPONENT_SECTION_ACCORDION_SECTION {
          ...AccordionSection
        }
      }
    }
  }
`

export default RepairBlogCategoryPage
